.MapMaincontainer {
  height: 100%;
  top:0;
  left:0;
  width:100%;
}

.prev-btn-container {
/*  float: inherit;*/
  position: absolute;
  top: 75%;
  z-index: 10;
  left: 1vw;
  width: 12.5vw;
  height: 12.5vw;
  border-radius: 0.35rem;
  /*margin-left: 1rem;*/
}
 
.next-btn-container {
/*  float: inherit;*/
  position: absolute;
  top: 75%;
  z-index: 10;
  right: 1vw;
  width: 12.5vw;
  height: 12.5vw;
  border-radius: 0.35rem;
  /*margin-left: 1rem;*/
}

.previous-btn {
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 0.35rem;
  
}

.next-btn {
  float: right;
  width: 100%;
  height: 100%;
  border-radius: 0.35rem;
}
@font-face {
  font-family: 'Sofia Pro';
  src: url('font/Sofia-Pro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('font/sofiapro-light.otf') format('opentype');
  font-weight: 300;
}

.form-label {
  font-family: 'Sofia Pro';
  font-size: 19px;
  color: #000000;
  margin-right: 1rem; /* Some spacing between label and input */
  text-align: left;   /* Keep it left-aligned */

}

.contact {
  font-family: 'Sofia Pro';
  margin-left: 7rem;
  font-size: 16px;
  color: #000000;
  width: 40%;
  text-align: center;
  padding: 1rem;
  font-weight: normal;
  white-space: pre-wrap;
}

.contact a[href^="mailto:"] {
  color: #452578;
  font-family: 'Sofia Pro';
  letter-spacing: 1px;
}

.vote-ended {
  font-family: 'Sofia Pro';
  font-size: 21px;
  color: #452578;
  text-align: center;
  font-weight: 500;
  white-space: pre-wrap;
  padding-top: 37px;
  padding-bottom: 37px;
}


/* Medium screens (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .vote-ended {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

/* Large screens (desktops/laptops) */
@media (min-width: 992px) and (max-width: 1199px) {
  .vote-ended {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* Extra-large screens (large desktops) */
@media (min-width: 1200px) {
  .vote-ended {
    padding-top: 37px;
    padding-bottom: 37px;
  }
}

.privacy {
  font-family: 'Sofia Pro';
  margin-left: 8rem;
  font-size: 14px;
  color: #000000;
  width: 40%;
  text-align: center;
  padding: 0.25rem;
  font-weight: normal;
  white-space: pre-wrap;
  
}

.MuiContainer-root{
  padding: 0px 0px 0px  0px !important;
}
.MuiBox-root-4 {
  padding: 0px !important;
}

.MuiInputBase-root {
  font-family: 'Sofia Pro' !important;
}

.MuiOutlinedInput-root {
      border: 0px  !important;
}

.MuiAutocomplete-inputRoot {
  background-color: #FFF;
}

.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}
.MuiInput-underline:before{
  border-bottom: 0px !important;
}
.MuiInput-underline:after{
  border-bottom: 0px !important;
}
.MuiInputBase-input {
  text-align: left !important; /* Forces text to align to the left */
  padding-left: 1rem !important; /* Ensures text starts from the left */
  padding-right: 2.5rem !important; /* Leaves space for the icon */
}

/* Ensure the calendar icon stays on the right */
.MuiInputAdornment-root {
  position: absolute !important;
  right: 0rem !important; /* Pushes icon to the right */
  pointer-events: none; /* Prevents unwanted clicks */
}
.MuiFormControl-marginNormal{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.App {

}
.App-textField-70{
  margin-top: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-container{
  display: flex;
  margin-bottom: 5%;
  margin-left: 6px;
  margin-top: 6px;
}
.header-logo{
  height: 3.5rem;
}
.header-text{
  align-self: center;
  margin-left: 6%;
  margin-top: 9px;
  width: 250px;
  height: 42.3434px;
}
.input-field-container {
  font-family: 'Sofia Pro' !important;
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;       /* Keep them aligned vertically */
  justify-content: space-between; /* Right-aligns everything in this container */
  padding: 0.9rem;
  font-weight: normal;
  width: 100%;               /* Make it responsive */
  box-sizing: border-box;
  gap: 1.5rem; /* Ensures even spacing between label, input, and icon */
}
h3{
  align-self: center;
  margin: 0 auto 0 0;
}
.input-field {
  height: 2.5rem;
  width: 15rem;
  max-width: 15rem;
  border: 2px solid #4b1f7d;
  border-radius: 0.25rem;
  text-align: left;
  font-size: 16px;
  font-family: 'Sofia Pro';
  color: #000000;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  box-sizing: border-box;
  align-self: flex-end;
  flex-grow: 0; /* Prevents it from stretching */
  flex-shrink: 0; /* Prevents it from shrinking */
  flex-basis: 15rem; /* Ensures it stays at the fixed width */
}

.small-input-field {
  height: 2.5rem;
  width: 11rem;
  border: 2px solid #4b1f7d;
  border-radius: 0.35rem;
  text-align: left;
  font-size: 16px;
  font-family: 'Sofia Pro';
  color: #000000;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  box-sizing: border-box;
}
.btn {
  width: 10rem;
  height: 3.25rem;
  background: #4b1f7d;
  color: #ffffff;
  font-size: 1.125em;
  font-weight: normal;
  display: block;
  margin: 45px auto;
  cursor: pointer;
  border: none;
  border-radius: 0.35rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.success-msg{
  font-family: 'Sofia Pro';
  font-size: 1.125em;
  color: #008000;
  margin: 1rem 0 0rem 0rem;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}
.error-msg{
  font-family: 'Sofia Pro';
  font-size: 1.125em;
  color: #ee0606;
  margin: 1rem 0 0rem 0rem;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.error {
  border: 2px solid #ee0606;
  border-radius: 0.35rem;
  display: flex;
}

.question-mark{
  padding-top: 11px;
}


a{
  color: #61b6f3;
  font-family: 'Sofia Pro';
  letter-spacing: 1px;
}

#sec a{
  font-family: 'Sofia Pro';
  margin-left: 4rem;
  font-size: 12px;
  color: #452578;
  width: 50%;
  text-align: center;
  padding: 0.25rem;
  font-weight: normal;
}


.link-holder{
  margin-left: 1rem;
}

.instructions{
  margin-left: 1rem;
  margin-right: 1.5rem;
  padding-bottom: 1rem;
  color: #452578;
  font-family: 'Sofia Pro';
  min-height: 60px;
}


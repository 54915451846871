@font-face {
  font-family: 'Sofia Pro';
  src: url('../../font/Sofia-Pro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../../font/sofiapro-light.otf') format('opentype');
  font-weight: 300;
}

.form-label {
  font-family: 'Sofia Pro';
  font-size: 19px;
  color: #000000;
  width: 50%;
  align-self: center;
}

.MuiInputAdornment-root {
  position: absolute;
  right: 0.8rem; /* Ensures proper spacing */
  z-index: 15; /* Keeps it above other elements */
  pointer-events: auto !important; /* Ensures clicks work */
}

.MuiInputBase-root {
  font-family: 'Sofia Pro' !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline: {
  border: 1px solid #FFFF00 !important;
}

.MuiAutocomplete-inputRoot {
  background-color: #FFF;
}

.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.MuiInput-underline:after {
  border-bottom: 0px !important;
}

.MuiInputBase-input {
  font-family: 'Sofia Pro' !important;
  padding: 10px 0 !important;
  font-size: 16px !important;
}


.App-textField-70 {
  margin-top: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mob-header-container {
  margin-bottom: 0%;
  margin-left: 50px;
  margin-top: 0;
  padding-top: 20px;
}

.mob-header-logo {
  height: 3.5rem;
}

.mob-header-text {
  align-self: center;
  margin-left: 6%;
  margin-top: 9px;
  width: 250px;
  height: 42.3434px;
}

.mob-input-field-container {
  font-family: 'Sofia Pro' !important;
  font-size: 16px;
  color: #000;
  display: flex;
  width: 19rem;
  padding: 1rem 3rem;
  font-weight: normal;
}

h3 {
  align-self: center;
  margin: 0 auto 0 0;
}

.mob-input-field {
  height: 2.5rem;
  width: 22rem;
  border: 2px solid #4b1f7d;
  border-radius: 0.35rem;
  text-align: center;
  font-size: 16px;
  font-family: 'Sofia Pro';
  color: #000000;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  box-sizing: border-box;
  
}

.mob-btn {
  width: 10rem;
  height: 3.25rem;
  background: #4b1f7d;
  color: #ffffff;
  font-size: 1.125em;
  font-weight: normal;
  display: block;
  margin: 45px auto;
  cursor: pointer;
  border: none;
  border-radius: 0.35rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.success-msg {
  font-family: 'Sofia Pro';
  color: #008000;
  margin: 1rem 0 0rem 0rem;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.error-msg {
  font-family: 'Sofia Pro';
  color: #ee0606;
  margin: 1rem 0 0rem 0rem;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.error {
  border: 2px solid #ee0606;
  outline: none;
}

.question-mark {
  padding-top: 11px;
}

.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  text-align: center;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}



@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
  .Maincontainer {
    justify-content: center;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 92px 307px 100px 100px;
    padding: 24px 0px 123px 0px;
    /*width: 420px;*/
    height: 100vh;
    overflow-x: hidden;
  }

  .mob-header-container {
    margin-bottom: 0%;
    margin-left: 50px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mob-header-logo {
    height: 3.5rem;
  }

  .mob-header-text {
    align-self: center;
    margin-left: 6%;
    margin-top: 9px;
    width: 237px;
    height: 42.3434px;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .Maincontainer {
    justify-content: center;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 92px 307px 100px 100px;
    height: 100vh;
    width: 100%;
    padding: 21px 0px 145px 0px;

  }

  .mob-header-container {
    margin-bottom: 0%;
    margin-left: 50px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mob-header-logo {
    height: 3.5rem;
  }

  .mob-header-text {
    align-self: center;
    margin-left: 6%;
    margin-top: 9px;
    width: 237px;
    height: 42.3434px;
  }
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .Maincontainer {
    justify-content: center;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 92px 307px 100px 100px;
    height: 100vh;
    padding: 39px 0px 300px 3px;
    width: 100%;
  }

  .mob-header-container {
    margin-bottom: 0%;
    margin-left: 50px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mob-header-logo {
    height: 3.5rem;
  }

  .mob-header-text {
    align-self: center;
    margin-left: 6%;
    margin-top: 9px;
    width: 237px;
    height: 42.3434px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .Maincontainer {
    justify-content: center;
    background-color: #ffffff;
    display: grid;
    height: 100vh;
    grid-template-rows: 92px 307px 100px 100px;
    width: 100%;
    padding: 39px 0px 300px 3px;

  }

  .mob-header-container {
    margin-bottom: 0%;
    margin-left: 50px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mob-header-logo {
    height: 3.5rem;
  }

  .mob-header-text {
    align-self: center;
    margin-left: 6%;
    margin-top: 9px;
    width: 237px;
    height: 42.3434px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .Maincontainer {
    justify-content: center;
    background-color: #ffffff;
    display: grid;
    height: 100vh;
    grid-template-rows: 92px 307px 100px 100px;
    width: 100%;
    padding: 39px 0px 300px 3px;

  }

  .mob-header-container {
    margin-bottom: 0%;
    margin-left: 50px;
    margin-top: 0;
    padding-top: 20px;
  }

  .mob-header-logo {
    height: 3.5rem;
  }

  .mob-header-text {
    align-self: center;
    margin-left: 6%;
    margin-top: 9px;
    width: 237px;
    height: 42.3434px;
  }
}
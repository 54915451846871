.previous-data-cont{
    margin-left: 1rem;
    margin-bottom: 50px;
    
}
.previos-data-box-cont{
    display: flex;
    margin: 15px 0;
    height: 2.5rem;
    background: #ffffff;
    width: 23rem;
    border: 0.75px solid #4b1f7d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.35rem;
}
.box{
    width: 8rem;
    padding: 4px;
    text-align: center;
    align-self: center;
    color: #8e8e8e;
    font-family: 'Sofia Pro';
    font-size: 16px;
}
.vertical-line{
    background: #4b1f7d;
    width: 2px;
    height: 1.5rem;
    align-self: center;
}
h3{
    font-family: 'Sofia Pro';
}
.previous-data-heading{
    font-weight: 400;
    letter-spacing: 1.1px;
}
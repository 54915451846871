.mob-info-container{
    display: flex;
    position: relative;
    margin-left: 18.5rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
}
.mob-info-text-container{
    z-index: 1;
    font-size: 15px;
    font-family: Sofia Pro;
    height: auto;
    width: 14rem;
    display: block;
    border: 1px solid #4b1e7d;
    padding: 8px;
    position: absolute;
    left: -218px;
    top: 50%;
    background: #ffffff;
    transform: translateY(10%);
}
.img{
   /* // margin-left: 1rem; */
}
.container{
    display: flex;
    position: relative;
}
.text-container{
    z-index: 1;
    font-size: 15px;
    font-family: Sofia Pro;
    height: auto;
    width: 7rem;
    display: block;
    border: 1px solid #4b1e7d;
    padding: 8px;
    position: absolute;
    left: 35px;
    top: 50%;
    background:#ffffff;
    transform: translateY(-50%);
}
.img{
    margin-left: 1rem;
    width: 20px;
}
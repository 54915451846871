.VoteMainContainer {
    background-color: #FFF;
    padding: 0px;
    height: 100vh;
    width: 100%;
}

.vote-previous-btn {
  float: left;
  width: 100%;
  height: 100%;
}


.prev-btn-container {
/*  float: inherit;*/
  position: absolute;
  top: 75%;
  z-index: 10;
  left: 1%;
  width: 12.5vw;
  height: 12.5vw;
  /*margin-left: 1rem;*/
}

.contact-container {
  position: relative;
  top: 50%;
  z-index: 10;
  display: block;
  width: 50%;
  left: 25%;
  right: 25%;
  text-align: center;
}

.VoteTopcontainer {
    /*justify-content: center;*/
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 25px 307px 100px 100px;
/*    padding: 0px 0px 0px 13px;*/
    height: 30vh;
    width: 100%;
    
}

.VoteBottomContainer {
    background-color: #ffffff;
    /*padding: 0px 10px 0px 10px;*/
    height: 70vh;
    width: 100%;
    display: block;
    overflow: auto;
}

.footer {
    text-align: center;
    width: 98%;
    margin-left: 1rem;
    margin-top:20px;
}

.Back-to-Submit {
    position: relative;
    top: 45%;
    width: 10rem;
    height: 3.25rem;
    background: #4b1f7d;
    color: #ffffff;
    font-size: 1.125em;
    font-weight: normal;
    display: block;
    margin: 0px auto;
    cursor: pointer;
    border: none;
    border-radius: 0.35rem;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.Footerheading {
    font-weight: 400;
    letter-spacing: 1.1px;
}

.contact-us {
  color: #452578;
}

@media only screen and (max-width: 767px) {
  .centered-on-mobile a[href^="mailto:"] {
    text-align: center;
    color: #452578;
  }
}

.vote-data-cont {
    margin-top: 25px;
    width: 95%;
    margin-left: 2.5%;
}

.vote-data-box-cont {
    display: flex;
    margin: 15px 0;
    height: 2.5rem;
    background: #ffffff;
    width: 100%;
    border: 0.75px solid #4b1f7d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.35rem;
}

.Votebox {
    width: 33%;
    padding: 4px;
    text-align: center;
    align-self: center;
    color: #8e8e8e;
    font-family: 'Sofia Pro';
    font-size: 16px;
}

.vote-vertical-line {
    background: #4b1f7d;
    width: 2px;
    height: 1.5rem;
    align-self: center;
}



h3 {
    font-family: 'Sofia Pro';
}

h4 {
    font-family: 'Sofia Pro';
    font-size: 20px;

}

.vote-data-heading {
    width: 100%;
    height: 16px;
    margin: 20px 68px 0px 0px;
    font-family: 'Sofia Pro';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

